@import '@/styles/_shared.scss';

@media print {
  .hero {
    display: none !important;
  }
}

:global(.listing-pages--filters-open) {
  .hero {
    &.hero--center {
      .hero__text {
        &-inner {
          h1 {
            @include breakpoint-up('lg') {
              font-size: 120px;
            }
          }
        }
      }
    }
  }
}

:global(.main--the-catch) {
  .hero.hero--bottom {
    h1.heading--catch {
      font-size: 72px;
      margin-bottom: -70px;
      @include breakpoint-up('sm') {
        font-size: 94px;
        margin-bottom: -104px;
      }
      @include breakpoint-up('md') {
        font-size: 204px;
        margin-bottom: -126px;
      }
      @include breakpoint-up('xl') {
        margin-bottom: -148px;
      }
    }
  }
}

.hero {
  position: relative;
  height: 90vh;
  max-height: 50vw;
  @include breakpoint-down('sm') {
    max-height: unset;
  }

  .hero__header {
    color: $alabaster;
    font-size: 60px;
  }

  .hero__subheader {
    color: #b7cd96;
    font-size: 32px;
    font-family: $bebas;
    text-transform: uppercase;
  }

  .hero__primary {
    color: $alabaster !important;
    font-size: 24px;
    font-family: $bebas;
  }

  .hero__secondary {
    color: $alabaster !important;
    text-transform: uppercase;
    font-size: 18px;
    margin-top: 20px;
    font-family: $bebas;
  }

  &.hero--smaller-text {
    &.hero--bottom,
    &.hero--center,
    &.hero--left,
    &.hero--center-transparent {
      h1 {
        font-family: $bebas;
        font-size: 36px;

        line-height: 100%;
        letter-spacing: 0.02em;
        @include breakpoint-up('sm') {
          font-size: 48px;
        }

        @include breakpoint-up('md') {
          font-size: 56px;
        }

        @include breakpoint-up('lg') {
          font-size: 60px;
        }

        @include breakpoint-up('xl') {
          font-size: 72px;
          line-height: 120%;
        }
      }
    }

    &.hero--smaller-text {
      &.hero--bottom,
      &.hero--center {
        h2 {
          font-size: 14px;
          line-height: 150%;

          @include breakpoint-up('lg') {
            font-size: 16px;
          }

          @include breakpoint-up('xl') {
            font-size: 18px;
          }
        }
      }
    }
  }

  &.top-margin {
    margin-top: 23px;

    @include breakpoint-up('md') {
      margin-top: 60px;
    }
  }

  &.bottom-margin {
    margin-bottom: 23px;

    @include breakpoint-up('md') {
      margin-bottom: 60px;
    }
  }

  // dynamic color modifiers
  &.text--light {
    color: $alabaster;

    .btn-wrap {
      a:first-child {
        min-width: 161px;
        padding: 0 20px;
      }

      a:not(:first-child) {
        color: $alabaster;
      }
    }
  }

  &.text--dark {
    color: $sitkablue;

    .btn-wrap {
      a:not(:first-child) {
        color: $sitkablue;
      }
    }
  }

  &.text--green {
    color: #b7cd96;

    .btn-wrap {
      a:not(:first-child) {
        color: #b7cd96;
      }
    }
  }

  &--video-enabled {
    .hero__text-inner {
      display: none;
    }
  }

  // hero__wrap

  &__main-wrap {
    position: relative;
    height: 100%;
    width: 100%;

    > div {
      height: 100%;
      width: 100%;
    }
  }

  &__wrap {
    position: relative;
    height: 100%;
    width: 100%;
  }

  // hero__text
  &__text {
    z-index: 1;

    &-inner {
      display: flex;
      flex-direction: column;
      align-items: center;

      h1 {
        font-size: 72px;
        margin-bottom: 12px;

        @include breakpoint-up('sm') {
          margin-bottom: 12px;
        }
      }

      h2 {
        margin: 0;
      }

      .btn-wrap {
        margin: 30px 0 24px;
        @include breakpoint-up('sm') {
          margin-top: 60px;
        }
      }

      .primary-btn-wrap {
        @include breakpoint-up('sm') {
          display: flex;
        }
        .btn {
          margin-right: 20px;
        }
      }

      .btn {
        min-width: 161px;
        padding: 0 20px;
        margin-bottom: 20px;
        // margin: 30px auto 24px auto;
        // @include breakpoint-up('sm') {
        //   margin-top: 60px;
        // }
      }

      a {
        text-align: center;
        margin-bottom: 12px;
      }
    }
  }

  // HERO BOTTOM ALIGNED STYLES
  // for the catch page
  &.hero--bottom {
    h1 {
      font-size: 72px;
      margin-bottom: 0;
      @include breakpoint-up('md') {
        font-size: 204px;
      }
    }

    .hero__text {
      position: absolute;
      bottom: 0;
      left: 28px;
      max-width: 319px;

      @include breakpoint-up('md') {
        left: 7.34%;
        max-width: 735px;
      }

      &-inner {
        text-align: left;
        align-items: flex-start;
      }
    }
  }

  // HERO CENTER STYLES
  &.hero--center,
  &.hero--with-container {
    .hero__text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 85.067%;
      max-width: 1108px;

      // hero__text-inner
      &-inner {
        text-align: center;
        margin-top: 20px;

        h1 {
          @include breakpoint-up('sm') {
            margin-bottom: 10px;
          }
          @include breakpoint-up('lg') {
            font-size: 144px;
            line-height: 1;
            margin-bottom: 20px;
          }
        }
        .btn {
          @include breakpoint-up('sm') {
            min-width: 250px !important;
          }
        }
      }
    }
  }

  &.hero--with-container {
    text-align: center;
    .hero__text {
      @include breakpoint-down('md') {
        position: static;
        width: 100%;
        transform: none;
      }
    }
    h1 {
      @include breakpoint-up('lg') {
        font-size: 144px;
        line-height: 1;
      }
      @include breakpoint-down('md') {
        color: $sitkablue;
        font-size: 52px;
      }
    }
    .btn-wrap {
      @include breakpoint-down('md') {
        display: none;
      }
    }
    .hero-video__action-btn {
      display: none;
      @include breakpoint-down('lg') {
        display: block;
      }
      @include breakpoint-down('md') {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        margin: 0;
        min-width: 140px;
        width: auto;
        height: 34px;
      }
    }
    img {
      border-radius: 12px;
    }
  }

  // HERO TRANSPARENT BG STYLES
  &.hero--center-transparent {
    .hero__text {
      padding: 57.03px 36.5px;
      box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.1);
      background-color: rgba(252, 250, 248, 0.95);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 2px;
      width: calc(100% - 60px);
      max-width: 1108px;

      @include breakpoint-up('sm') {
        padding: 8.05% 7.45%;
        border-radius: 12px;
        width: calc(100% - 100px);
      }

      @include breakpoint-up('md') {
        width: calc(100% - 120px);
      }

      @include breakpoint-up('lg') {
        padding: 0;
        min-height: 623px;
        display: flex;
        align-items: center;
        width: calc(100% - 170px);
      }

      // hero__text-inner
      &-inner {
        text-align: center;
        max-width: 822px;
        margin: auto;

        a {
          @include breakpoint-up('sm') {
            max-width: 392px;
          }
        }

        .btn {
          min-width: 161px;

          @include breakpoint-up('sm') {
            width: 392px;
          }
        }
      }
    }
  }

  // HERO ALIGNED LEFT STYLES
  &.hero--left {
    .hero__text {
      position: absolute;
      top: 60px;
      left: 28px;
      max-width: 319px;

      @include breakpoint-up('sm') {
        transform: translateY(-50%);
        top: 50%;
        left: 7.34%;
        max-width: 735px;
      }

      // hero__text-inner
      &-inner {
        text-align: left;
        align-items: flex-start;
      }
    }
  }
}

.hero-video__action-btn {
  min-width: none;
  max-width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  &:global(.alabaster) {
    svg {
      * {
        fill: $sitkablue;
      }
    }
  }

  svg {
    width: 17px;
    margin-right: 10px;
  }
}

.hero-video__wrap {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  iframe {
    width: 100%;
    height: 100%;

    @include breakpoint-up('md') {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  > div {
    height: 100%;
  }
}

.hero__prefer_to_talk {
  position: absolute;
  right: 20px;
  top: 20px;
  text-transform: uppercase;
  padding: 20px;
  background-color: #163144cc;
  font-size: 22px;
  border-radius: 36px;
  color: #b7cd96;
  z-index: 1;
  font-family: $bebas;

  span {
    color: $alabaster;
  }

  @include breakpoint-down('sm') {
    display: none;
  }
}
